import React, { Component } from 'react';
import ContactForm from './ContactForm';
import axios from 'axios';

const BASE_URL = 'https://my-json-server.typicode.com/themeland/json-server-1/themeOneContactSection';

class ContactSection extends Component {
    state = {
        data: {
            heading: 'Contact us!',
            headingText: 'Send us your requirements, drawings or pictures and ask for a quote. We will contact you shortly.',
            headingTextTwo: 'Send us your requirements, drawings or pictures and ask for a quote. We will contact you shortly.',
            content: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',
            iconList: [
                {
                    id: 1,
                    iconClass: 'fas fa-home',
                    text: '24 Squadron Drive, Hobsonville, Auckland 0616',
                    link: 'https://maps.app.goo.gl/sdGao5AhUhJ3o2Pk6'
                },
                {
                    id: 2,
                    iconClass: 'fas fa-phone-alt',
                    text: '+64221227470',
                    link: 'tel:+64221227470'
                },
                {
                    id: 3,
                    iconClass: 'fas fa-envelope',
                    text: 'info@sapsan.co.nz',
                    link: 'mailto:info@sapsan.co.nz'
                }
            ]
        },
        iconList: []
    }
    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{this.state.data.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingTextTwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-5">
                        {/* Contact Us */}
                        <div className="contact-us">
                            {/* <p className="mb-3">{this.state.data.content}</p> */}
                            <ul>
                                {this.state.data.iconList.map((item, idx) => {
                                    return(
                                        <li key={`ci_${idx}`} className="py-2">
                                            <a className="media" target="_blank" href={item.link}>
                                                <div className="social-icon mr-3">
                                                    <i className={item.iconClass} />
                                                </div>
                                                <span className="media-body align-self-center">{item.text}</span>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                        <ContactForm />
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;
