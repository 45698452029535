import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = 'https://my-json-server.typicode.com/themeland/json-server-1/themeOneFooterSection';

class FooterSection extends Component {
    state = {
        data: {
            'image': '/img/sapsan-primary.svg',
            'text': 'You can also contact us using:',
            'linkText_1': 'Useful Links',
            'iconList': [
                {
                    'id': 1,
                    'link': 'facebook',
                    'iconClass': 'fab fa-facebook-f',
                    'url': 'https://www.facebook.com/SapsanLtd'
                },
                {
                  'id': 2,
                  'link': 'whatsapp',
                  'iconClass': 'fab fa-whatsapp',
                  'url': 'https://api.whatsapp.com/send?phone=+64221227470'
                }
                /*{
                    'id': 2,
                    'link': 'twitter',
                    'iconClass': 'fab fa-twitter',
                    'url': ''
                },
                {
                    'id': 3,
                    'link': 'google-plus',
                    'iconClass': 'fab fa-google-plus-g',
                    'url': ''
                },
                {
                    'id': 4,
                    'link': 'vine',
                    'iconClass': 'fab fa-vine',
                    'url': ''
                }*/
            ],
            'footerList_1': [
                {
                    'id': 1,
                    'text': 'Home',
                    'link': '#root'
                },
                {
                    'id': 2,
                    'text': 'What we do',
                    'link': '#services'
                },
                {
                    'id': 3,
                    'text': 'Our work',
                    'link': '#gallery'
                },
                {
                    'id': 4,
                    'text': 'Reviews',
                    'link': '#review'
                },
                {
                    'id': 5,
                    'text': 'Contact',
                    'link': '#contact'
                }
            ],
            'footerList_4': [
                {
                    'id': 1,
                    'text': 'Affiliate program'
                },
                {
                    'id': 2,
                    'text': 'Terms & Conditions'
                },
                {
                    'id': 3,
                    'text': 'Privacy Policy'
                },
                {
                    'id': 4,
                    'text': 'Refund Policy'
                }
            ]
        }
    }
    render() {
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-sm-6 col-lg-3">
                                    {/* Footer Items */}
                                    <div className="footer-items">
                                        {/* Footer Title */}
                                        <h3 className="footer-title mb-2">{this.state.data.linkText_1}</h3>
                                        <ul>
                                            {this.state.data.footerList_1.map((item, idx) => {
                                                return(
                                                    <li key={`flo_${idx}`} className="py-2"><a className="scroll" href={item.link}>{item.text}</a></li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-3">
                                    {/* Footer Items */}
                                    <div className="footer-items">
                                        {/* Logo */}
                                        <a className="navbar-brand" href="/#">
                                            <img className="footer-logo" src={this.state.data.image} alt="" />
                                        </a>
                                        <p className="mt-2 mb-3">{this.state.data.text}</p>
                                        {/* Social Icons */}
                                        <div className="social-icons d-flex">
                                            {this.state.data.iconList.map((item, idx) => {
                                                return(
                                                    <a key={`fi_${idx}`} className={item.link} href={item.url}>
                                                        <i className={item.iconClass} />
                                                        <i className={item.iconClass} />
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights 2021 Sapsan NZ All rights reserved.</div>
                                {/* Copyright Right */}
                                <div className="copyright-right">Site made by <a href="https://www.fuzzoft.com/">Fuzzoft</a></div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default FooterSection;
