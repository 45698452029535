import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneServiceSection";

class ServiceSection extends Component {
    state = {
        data: {
            "heading": "Make your Device Manage Everything For you",
            "headingTwo": "Share your photos with friends easily",
            "headingThree": "Smart and secure mobile banking",
            "headingFour": "More productivity with less effort",
            "headingFive": "Organize all your media content easily",
            "headingSix": "Work faster with powerful filters",
            "headingSeven": "What we do",
            "buttonText": "Contact us",
            "thumbOne": "/img/features_thumb.png",
            "thumbTwo": "/img/service_thumb_2.png",
            "thumbThree": "/img/welcome_mockup_2.png",
            "thumbFour": "/img/discover_thumb.png",
            "thumbFive": "/img/service_thumb_1.png",
            "thumbSix": "/img/discover_thumb_2.png"
        },
        serviceData: [
            {
                "id": 1,
                "iconClass": "fas fa-home",
                "text": "Ceiling (Suspended System, Direct Fix Ceiling System, Tile Ceiling System etc.)"
            },
            {
                "id": 2,
                "iconClass": "fas fa-border-all",
                "text": "Steel Stud Wall Framing (Wall Framing System, Acoustic Wall System etc.)"
            },
            {
                "id": 3,
                "iconClass": "fab fa-buffer",
                "text": "Plasterboard Linings (All types of drywall)"
            }
        ]
    }
    render() {
        return (
            <section id="services" className="section service-area overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">
                            <h2 className="text-capitalize mb-4">{this.state.data.headingSeven}</h2>
                            {/* Service List */}
                            <ul className="service-list">
                                {this.state.serviceData.map((item, idx) => {
                                    return(
                                        <div key={`so_${idx}`}>
                                            {/* Single Service */}
                                            <li className="single-service media py-2">
                                                <div className="service-icon pr-4">
                                                    <span><i className={item.iconClass} /></span>
                                                </div>
                                                <div className="service-text media-body">
                                                    <p>{item.text}</p>
                                                </div>
                                            </li>
                                        </div>
                                    );
                                })}
                            </ul>
                            <a href="/#contact" className="scroll btn btn-bordered mt-4">{this.state.data.buttonText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-lg-block">
                        {/* Service Thumb */}
                        <div className="service-thumb mx-auto">
                        <img src={this.state.data.thumbOne} alt="" />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSection;
