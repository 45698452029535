import React, { Component } from "react";
import axios from 'axios';


class ContactForm extends Component {

  CONTACT_FORM_URL = 'https://e69jaznq14.execute-api.ap-southeast-2.amazonaws.com/default/contactFormEmailFunction'

  constructor(props) {
    super(props)
    this.myForm = React.createRef()
  }

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    attachments: null,
    isSending: false,
    isSent: false,
    error: false,
  }

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.files || event.target.value
    })
  }
  submitHangler = async (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      isSending: true,
    });


    const form = this.myForm.current;
    const formData = new FormData(form);
    const files = formData.getAll('attachments');
    const formJson = Object.fromEntries(formData.entries());

    formJson['attachments'] = [];
    for (const file of files) {
      if (!file.name) {
        continue;
      }
      formJson['attachments'].push(file.name);
    }

    console.log('attachments', formJson);

    try {
      const res = await axios.post(`${this.CONTACT_FORM_URL}`, formJson, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });

      console.log('sent!', res);
      if (res.data.status !== 'success') {
        this.setState({
          ...this.state,
          isSent: false,
          isSending: false,
          error: true
        });
        return;
      }

      for (const file of files) {
        if (!file.name) {
          continue;
        }
        const response = await axios.put(res.data.s3Urls[file.name], file);
      }

      this.myForm.current.reset()
      this.setState({
        name: '',
        email: '',
        subject: '',
        message: '',
        attachments: null,
        isSending: false,
        isSent: true,
        error: false
      });

    } catch (err) {
      console.log(err)
      this.setState({
        ...this.state,
        isSent: false,
        isSending: false,
        error: true
      });
    }
  }

  render() {
    if (this.state.isSent) {
      return (
        <div className="contact-box text-center">
          <h2>Thank you!</h2>
          <p>
            We've received your message and will contact you shortly.
          </p>
        </div>
      )
    }
    return (
      <div className="contact-box text-center">
        { this.state.isSending &&
          <div className="contact-box text-center">
            <h2>One moment...</h2>
            <p>
              Sending your message...
            </p>
          </div>
        }
        { this.state.error && !this.state.isSending &&
          <div className="error">
            <h3>Oof, something went wrong, why not try again a bit later or send us an email directly?</h3>
          </div>
        }
        <form
          ref={this.myForm}
          onSubmit={this.submitHangler}
          className="contact-form"
          style={{ display: this.state.isSending ? 'none' : '' }}
        >
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                required="required"
                onChange={this.changHandler}
                value={this.state.name}
                />
              </div>
              <div className="form-group">
                <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Email"
                required="required"
                onChange={this.changHandler}
                value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Subject"
                required="required"
                onChange={this.changHandler}
                value={this.state.subject}
                />
              </div>
            </div>
            <div className="col-12">
                <div className="form-group">
                    <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.message}
                    />
                </div>
            </div>
            <div className="col-12">
                <label className="attachment-button btn btn-lg btn-block btn-secondary mt-3">
                  <input
                    name="attachments"
                    type="file"
                    multiple="multiple"
                    onChange={this.changHandler}
                  />
                  <span className="text-white pr-3"><i className="fas fa-paperclip" /></span>
                  { !this.state.attachments ? "Attach files" : `${this.state.attachments.length} file${ this.state.attachments.length > 1 ? 's' : ''} attached` }
                </label>
                <button
                    type="submit"
                    className="btn btn-lg btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>
                    Send Message
                </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
