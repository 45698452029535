import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';

const initData = {
    heading: "What our customers are saying",
    headingText: "Feedback from our customers is very important to us, it helps to continually evolve our services.",
    headingTextTwo: "Feedback from our customers is very important to us, it helps to continually evolve our services."
}

const data = [
    {
        id: "1",
        avatorImg: "/img/avatar-1.png",
        reviewer: "Mikhail Alexeev",
        post: "Almaz Construction",
        text: "I often work with Arthur and can recommend if you need a quality installation of any type of ceiling!",
        comments: "Professional work!",
        quotIcon: "/img/quote.png",
        icon_1: "fas fa-star text-warning",
        icon_2: "fas fa-star text-warning",
        icon_3: "fas fa-star text-warning",
        icon_4: "fas fa-star text-warning",
        icon_5: "fas fa-star text-warning"
    },
    {
        id: "2",
        avatorImg: "/img/avatar-2.png",
        reviewer: "Andrew Shotter",
        post: "SCD BUILDERS LTD",
        text: "Sapsan was a subcontractor on several of our projects and completed the steel wall grid installation as planned and on time.",
        comments: "Quickly and efficiently",
        quotIcon: "/img/quote.png",
        icon_1: "fas fa-star text-warning",
        icon_2: "fas fa-star text-warning",
        icon_3: "fas fa-star text-warning",
        icon_4: "fas fa-star text-warning",
        icon_5: "fas fa-star-half-alt text-warning"
    },
    {
        id: "3",
        avatorImg: "/img/avatar-3.png",
        reviewer: "Philip Badenhorst",
        post: "NZ Force Construction",
        text: "Arthur worked on our project, we had easy communication and he completed work very fast.",
        comments: "Quickly and on time",
        quotIcon: "/img/quote.png",
        icon_1: "fas fa-star text-warning",
        icon_2: "fas fa-star text-warning",
        icon_3: "fas fa-star text-warning",
        icon_4: "fas fa-star text-warning",
        icon_5: "fas fa-star text-warning"
    }
]

class ReviewSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="review" className="review-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{this.state.initData.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.initData.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.initData.headingTextTwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row reviews-slider">
                        {this.state.data.map((item, idx) => {
                            return(
                                <div key={`ro_${idx}`} className="single-review-container">
                                    {/* Single Review */}
                                    <div className="single-review card">
                                        {/* Card Top */}
                                        <div className="card-top p-4 column">
                                            <div className="header row justify-content-between">
                                                <h4 className="text-primary mt-4 mb-3">{item.comments}</h4>
                                                {/* Quotation Icon */}
                                                <img className="avatar-md" src={item.quotIcon} alt="" />
                                            </div>
                                            {/* Review Text */}
                                            <Dotdotdot clamp="auto">
                                                <p>{item.text}</p>
                                            </Dotdotdot>
                                        </div>
                                        {/* Reviewer */}
                                        <div className="reviewer media bg-gray p-4">
                                            {/* Reviewer Thumb */}
                                            {/* <div className="reviewer-thumb">
                                                <img className="avatar-lg radius-100" src={item.avatorImg} alt="" />
                                            </div> */}
                                            {/* Reviewer Media */}
                                            <div className="reviewer-meta media-body align-self-center ml-4">
                                                <h5 className="reviewer-name color-primary mb-2">{item.reviewer}</h5>
                                                <h6 className="text-secondary fw-6">{item.post}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewSection;
