import React, { Component } from 'react';

const initData = {
    iconClass: "far fa-lightbulb text-primary mr-1",
    preHeading: "Awesome",
    preHeadingspan: "Interface",
    heading: "Works performed by us",
    headingText: "Extensive work experience helps us to fulfill any customers wishes.",
    headingTexttwo: "Extensive work experience helps us to fulfill any customers wishes."
}

const data = [
    {
        image: '/img/gallery-1.jpg'
    },
    {
        image: '/img/gallery-2.jpg'
    },
    {
        image: '/img/gallery-3.jpg'
    },
    {
        image: '/img/gallery-4.jpg'
    },
    {
        image: '/img/gallery-5.jpg'
    },
    {
        image: '/img/gallery-6.jpg'
    },
    {
        image: '/img/gallery-8.jpg'
    },
    {
        image: '/img/gallery-9.jpg'
    },
    {
        image: '/img/gallery-10.jpg'
    },
    {
        image: '/img/gallery-11.jpg'
    },
    {
        image: '/img/gallery-12.jpg'
    },
    {
        image: '/img/gallery-13.jpg'
    },
    {
        image: '/img/gallery-14.jpg'
    }
]

class ScreenshotSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="gallery" className="section bg-overlay screenshots-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        {/* <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-3">
                            <i className={this.state.initData.iconClass} />
                            <span className="text-primary">{this.state.initData.preHeading}</span> {this.state.initData.preHeadingspan}
                        </span> */}
                        <h2 className="text-capitalize text-white">{this.state.initData.heading}</h2>
                        <p className="d-none d-sm-block mt-4 text-white">{this.state.initData.headingText}</p>
                        <p className="d-block d-sm-none mt-4 text-white">{this.state.initData.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {/* App Screenshot Slider Area */}
                            <div className="app-screenshots">
                                {/* Single Screenshot Item */}
                                {data.map((item, idx) => {
                                    return(
                                        <div key={`so_${idx}`} className="single-screenshot">
                                            <img src={item.image} alt="" />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ScreenshotSection;
